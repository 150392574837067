import {Component} from '@angular/core';
import {
    Action,
    FrontPlugBusinessService,
    IziviaLoggerService,
    OTPSession,
    PricingContextDto,
    RemoteResponse,
    RemoteStatus,
    StationDto,
    UserAction
} from 'lib-front';
import {ActivatedRoute} from '@angular/router';
import {NavController, ViewDidEnter, ViewWillEnter} from '@ionic/angular';
import {NewChargeContext, NewChargeStepType, RemoteInfoMedia} from 'lib-mobile';
import {Observable, of} from 'rxjs';

@Component({
    selector: 'new-charge-summary-view',
    templateUrl: './summary.view.html',
    styleUrls: ['./summary.view.scss']
})
export class NewChargeSummaryView  implements ViewWillEnter, ViewDidEnter {
    // dirty hack to enable type-safety to the view
    Action = Action;

    newChargeContext: NewChargeContext;
    step: NewChargeStepType = NewChargeStepType.SUMMARY;
    station: StationDto;
    canRemoteStart: boolean;
    remoteStart: boolean;
    remoteRequest: RemoteInfoMedia;
    pricingContextObs: Observable<PricingContextDto>;

    constructor(private readonly route: ActivatedRoute,
        private readonly navController: NavController,
        private readonly plugBusinessService: FrontPlugBusinessService,
        private readonly loggerService: IziviaLoggerService) {}

    ionViewWillEnter() {
        this.station = this.route.snapshot.data.station;
        this.newChargeContext = this.route.snapshot.data.newChargeContext;
        this.pricingContextObs = of(this.station.pricingContext);

        this.loggerService.debug('new charge summary: context found', this.newChargeContext);

        this.canRemoteStart = this.newChargeContext.chargePoint.currentUserActions.includes(UserAction.REMOTE_START)
                && (!this.newChargeContext.otpSession || this.doesOtpSessionAllowRemoteStart());
    }

    ionViewDidEnter() {
        // force event refresh in order to show a notification badge in the footer tabs
    }

    goToPreviousSlide() {
        this.navController.navigateBack([`/app/new-charge/station/${this.station.id}/media-choice`], {
            queryParams: this.newChargeContext.toQueryParam()
        });
    }

    showRemoteStart() {
        this.remoteStart = true;

        this.remoteRequest = new RemoteInfoMedia(
            this.newChargeContext.otpSession.stationRef,
            this.newChargeContext.otpSession.chargePointRef,
            this.newChargeContext.otpSession.plugRef,
            this.newChargeContext.otpSession.otpCode
        );
    }

    handleRemoteResponse(remoteResponse: RemoteResponse) {
        if (remoteResponse.status === RemoteStatus.DONE) {
            if (remoteResponse.chargeRef) {
                const url = `/events/details/charge/${remoteResponse.chargeRef}/otp/${this.newChargeContext.otpSession.otpCode}`;
                this.navController.navigateRoot(url, {replaceUrl : true, animationDirection : 'forward'});
            } else {
                this.loggerService.error('[handleRemoteResponse]: remoteResponse.chargeRef === null', remoteResponse);
                this.navController.navigateRoot('/home',
                    {replaceUrl : true, animationDirection : 'forward'});
            }
        } else if (remoteResponse.status === RemoteStatus.TIMEOUT) {
            this.loggerService.debug('[handleRemoteResponse] TIMOUT', remoteResponse);
            this.navController.navigateForward([`/app/new-charge/station/${this.station.id}/retry`], {
                queryParams: this.newChargeContext.toQueryParam()
            });
        } else {
            this.remoteStart = false;
            this.remoteRequest = null;
            this.navController.navigateForward([`/app/new-charge/station/${this.station.id}/retry`], {
                queryParams: this.newChargeContext.toQueryParam()
            });
        }
    }

    goToHome() {
        this.navController.navigateRoot(['/home'], {
            replaceUrl: true
        });
    }

    private doesOtpSessionAllowRemoteStart(): boolean {
        const otpSession = this.newChargeContext.otpSession;

        return !!otpSession
            && !!otpSession.stationRef
            && !!otpSession.chargePointRef
            && !!otpSession.plugRef
            && !!otpSession.otpCode;
    }
}
