import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'enterMedia',
    templateUrl: './enterMedia.component.html',
    styleUrls: ['./enterMedia.component.scss']
})
export class EnterMediaComponent {

    @Input() otpError: boolean = false;
    @Input() otpCode: string;
    @Input() otpErrorKey: string;
    @Output() otpCodeChanged: EventEmitter<string> = new EventEmitter<string>();

}
