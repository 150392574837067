<ion-header>
    <ion-toolbar>
        <ion-img class="logo" slot="start" src="../../assets/images/png/logo.png" alt="logo"></ion-img>
        <ion-buttons slot="end">
            <support-button [color]="'secondary'"></support-button>
        </ion-buttons>
        <ion-select class="selectLanguage" (ionChange)="changeLanguage($event)"
                    okText="{{'common.ok' | translate}}"
                    cancelText="{{'common.cancel' | translate}}"
                    [selectedText]="translateService.currentLang | uppercase"
                    [value]="translateService.currentLang">
            <ion-select-option *ngFor="let lang of languages"
                               [value]="lang">
                <img src="../assets/images/jpg/flags/{{lang}}.jpg" alt="flag-{{lang}}"/>
                {{lang | translate}}
            </ion-select-option>
        </ion-select>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div class="backgroundStyle">
        <div class="homeContent">
            <h1 class="homeTitle">{{'home.title' | translate}}</h1>
            <h6 class="homeSubtitle" [innerHtml]="'home.subtitle' | translate"></h6>
            <div class="homeEvent">
                <h3 class="homeEvent title">{{'home.events.current' | translate}}</h3>
                <div class="homeContent-list">
                    <ion-list [class.ghost]="ghostMode">
                        <otp-session-shortcut class="homeEvent-inProgress"
                                              *ngFor="let otpSession of currentOtpSessions; trackBy: trackByOtpSessionId"
                                              [otpSessionDto]="otpSession"></otp-session-shortcut>
                        <ion-item class="homeEvent-empty"
                                  *ngIf="currentOtpSessions && currentOtpSessions.length === 0">
                            {{'home.events.noEvent' | translate}}
                        </ion-item>
                    </ion-list>
                </div>
            </div>
        </div>

        <div class="homeButtons">
            <ion-button color="secondary" (click)="goToBuyCode()" expand="block">
                {{'home.charge.start' | translate}}
            </ion-button>
            <ion-button class="outlineButton" color="secondary" (click)="goToStopCharge()" expand="outline">
                {{'home.charge.stop' | translate}}
            </ion-button>
        </div>
    </div>

</ion-content>
